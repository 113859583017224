export function asHex(value) {
  const h = `00${value.toString(16)}`;
  return h.substring(h.length - 2, h.length).toUpperCase();
}

export function shortName(text) {
  const parts = text.toLocaleUpperCase().split(/\s+/);
  if (parts.length > 1) {
    return parts[0][0] + parts[1][0];
  } else {
    return parts[0].substring(0, 2);
  }
}
