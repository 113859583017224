import { asHex } from '@/utilities/text';

const color_variants = {
  black: false, dark: false, light: false, white: false,
  primary: true, link: true, info: true, success: true, warning: true, danger: true,
};

export const TAG_COLORS = Object.entries(color_variants).flatMap(([color, canBeLight]) => {
  const sets = [
    {
      id: color,
      label: color,
      classes: [`is-${color}`],
    },
  ];
  if (canBeLight) {
    sets.push({
      id: `light-${color}`,
      label: `light ${color}`,
      classes: [`is-${color}`, 'is-light'],
    });
  }
  return sets;
});

export function colorProps(colorName) {
  return TAG_COLORS.find(e => e.id === colorName);
}

export function colorHash(inputString) {
  let sum = 0;

  for (const i in inputString) {
    sum += inputString.charCodeAt(i);
  }

  const r = ~~(('0.' + Math.sin(sum + 1).toString().substring(6)) * 256);
  const g = ~~(('0.' + Math.sin(sum + 2).toString().substring(6)) * 256);
  const b = ~~(('0.' + Math.sin(sum + 3).toString().substring(6)) * 256);

  const rgb = 'rgb(' + r + ', ' + g + ', ' + b + ')';

  const color = Math.round(((r * 299) + (g * 587) + (b * 114)) / 1000);
  const light = (color > 125);

  const hex = `#${asHex(r)}${asHex(g)}${asHex(b)}`;

  return { r, g, b, rgb, hex, light };
}
